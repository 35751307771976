<template>
  <div class="page-wrapper parameters-page">
    <Navigation
      :router="{name: 'clients-templates', params: {clientId: clientId}}"
      :client="client"
      :title="$t('PARAMETERS.TITLE')"
      :subtitle="template ? template.display_name + ' / ' + template.name : ''"
    />

    <EmptyList
      v-if="!loading && form.parameters.length === 0 && optinTypes.length === 0"
      :message="$t('PARAMETERS.NO_DATA')"
    />

    <Processing
      v-if="loadingCalculate"
      :message="$t('PROCESSING')"
      :new-run="newRun"
      :template="template"
    />

    <el-button
      v-show="loadingCalculate"
      class="cancel-button"
      round
      type="primary"
      :loading="loadingCancellation"
      @click="cancelCalculation"
    >
      {{ $t('BUTTONS.CANCEL_CALCULATION') }}
    </el-button>

    <el-form
      v-if="!loadingCalculate"
      ref="form"
      v-loading="loading"
      :rules="rules"
      class="parameters-form"
      :model="form"
      label-width="300px"
    >
      <!-- OPTIN TYPES -->
      <el-form-item
        :label="$t('PARAMETERS.TYPE_LABEL')"
        prop="optinType"
        :required="optinTypeIsRequired"
      >
        <el-select
          v-model="form.optinType"
          placeholder=""
          :clearable="!optinTypeIsRequired"
        >
          <el-option
            v-for="item in optinTypes"
            :key="item.crm_id"
            :label="item.name"
            :value="item.crm_id"
          />
        </el-select>
      </el-form-item>

      <!-- SORTIE CIBLE -->
      <el-form-item
        :label="$t('PARAMETERS.TARGET_OUTPUT')"
        required
        prop="sortieCible"
      >
        <el-radio-group
          v-model="form.sortieCible"
          placeholder=""
        >
          <el-radio
            key="1"
            label="1"
          >
            {{ $t('PARAMETERS.YES_LABEL') }}
          </el-radio>
          <el-radio
            key="0"
            label="0"
          >
            {{ $t('PARAMETERS.NO_LABEL') }}
          </el-radio>
        </el-radio-group>
      </el-form-item>

      <!-- include non-contactable (email campaigns only) -->
      <el-form-item
        v-if="isEmail"
        :label="$t('PARAMETERS.INCLUDE_NON_CONTACTABLE')"
        required
        prop="includeNonContactable"
      >
        <el-radio-group
          v-model="form.includeNonContactable"
          placeholder=""
        >
          <el-radio
            key="1"
            label="1"
          >
            {{ $t('PARAMETERS.YES_LABEL') }}
          </el-radio>
          <el-radio
            key="0"
            label="0"
          >
            {{ $t('PARAMETERS.NO_LABEL') }}
          </el-radio>
        </el-radio-group>
      </el-form-item>

      <!-- trigger_id for on-demand campaigns -->
      <el-form-item
        v-if="isAPITrigger"
        :label="$t('PARAMETERS.TRIGGER_ID')"
        required
        prop="triggerId"
      >
        <el-input
          v-model="form.triggerId"
          placeholder=""
        />
      </el-form-item>

      <!-- App id for Push notifications -->
      <el-form-item
        v-if="isPushNotification"
        label="DDL_Apps_id"
        prop="appId"
        required
      >
        <el-select
          v-model="form.appId"
          placeholder=""
        >
          <el-option
            v-for="item in applications"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <!-- LIST PARAMETERS -->
      <div
        v-for="(parameter, index) in form.parameters"
        :key="parameter.id"
      >
        <div v-if="parameter.parameter !== 'pays_iso'">
          <!-- INPUT -->
          <el-form-item
            v-if="parameter.displayed && parameter.type === 1 && parameter.parameter !== 'NomFichier'"
            :label="parameter.display_name ? parameter.display_name : parameter.parameter"
            :required="parameter.required"
            :prop="`parameters[${index}].display_name`"
          >
            <el-input
              v-model="parameter.default_value"
              :maxlength="parameter.maxlength"
              placeholder=""
            />
          </el-form-item>

          <!-- NUMBER INPUT -->
          <el-form-item
            v-if="parameter.displayed && parameter.type === 2 && parameter.parameter !== 'Id_TypeConsentement'"
            :label="parameter.display_name ? parameter.display_name : parameter.parameter"
            :required="parameter.required"
          >
            <el-input-number
              v-model="parameter.default_value"
              :controls="false"
              :maxlength="parameter.maxlength"
              placeholder=""
            />
          </el-form-item>

          <!-- RADIO BUTTON -->
          <el-form-item
            v-if="parameter.displayed && parameter.type === 3 && parameter.parameter !== 'sortiecible'"
            :label="parameter.display_name ? parameter.display_name : parameter.parameter"
            :required="parameter.required"
            :prop="`parameters[${index}].default_value`"
            :rules="{required: parameter.required, message: parameter.display_name + ' is required', trigger: 'blur'}"
          >
            <el-radio-group
              v-model="parameter.default_value"
              placeholder=""
            >
              <el-radio
                key="1"
                label="1"
              >
                {{ $t('PARAMETERS.YES_LABEL') }}
              </el-radio>
              <el-radio
                key="0"
                label="0"
              >
                {{ $t('PARAMETERS.NO_LABEL') }}
              </el-radio>
            </el-radio-group>
          </el-form-item>

          <!-- SELECT NORMAL TYPE 4 -->
          <el-form-item
            v-if="shouldDisplayDropdown(parameter)"
            :label="parameter.display_name ? parameter.display_name : parameter.parameter"
            :prop="`parameters[${index}].default_value`"
            :rules="{required: parameter.required, message: parameter.display_name + ' is required', trigger: 'blur'}"
          >
            <el-select
              v-model="parameter.default_value"
              :default-first-option="true"
              placeholder=""
              clearable
              filterable
              remote
              :remote-method="remoteMethod"
              :no-data-text="$t('NO_MATCHING_DATA')"
              :loading-text="$t('LOADING')"
              :loading="loadingValues"
              @focus="focusSelect(parameter)"
            >
              <el-option
                v-for="item in parameter.values"
                :key="item.id"
                :label="buildOptionLabel(parameter, item)"
                :value="item.id"
              />
            </el-select>
            <div
              :ref="`parameter-${parameter.id}`"
              class="parameters-form__msg"
            >
              <el-alert
                title=""
                type="error"
                :description="$t('NO_TABLE_DATA') + ' : ' + parameter.ref_tablename"
                :closable="false"
              />
            </div>
            <div
              v-if="errorParams.indexOf(parameter.id) !== -1"
              class="parameters-form__config-error"
            >
              <el-alert
                title=""
                type="error"
                :description="$t('PARAMETERS.REFERENCIAL.ERROR_REF_TABLE')"
                :closable="false"
              />
            </div>
          </el-form-item>

          <!-- MULTI SELECTION TYPE 5 -->
          <el-form-item
            v-if="parameter.displayed && parameter.type === 5"
            :label="parameter.display_name ? parameter.display_name : parameter.parameter"
            :prop="`parameters[${index}].default_value`"
            :rules="{required: parameter.required, message: parameter.display_name + ' is required', trigger: 'blur'}"
          >
            <el-select
              :ref="'parameter' + parameter.id"
              v-model="parameter.default_value"
              multiple
              filterable
              placeholder=""
              remote
              :remote-method="remoteMethod"
              :no-data-text="$t('NO_MATCHING_DATA')"
              :loading-text="$t('LOADING')"
              :loading="loadingValues"
              @focus="focusSelect(parameter)"
            >
              <el-option
                v-for="item in parameter.values"
                :key="item.id"
                :label="item.id + ' - ' + item.content"
                :value="item.id"
              />
            </el-select>
            <div
              :ref="'parameter-' + parameter.id"
              class="parameters-form__msg"
            >
              <el-alert
                title=""
                type="error"
                :description="$t('NO_TABLE_DATA') + ' : ' + parameter.ref_tablename"
                :closable="false"
              />
            </div>
            <div
              v-if="errorParams.indexOf(parameter.id) !== -1"
              class="parameters-form__config-error"
            >
              <el-alert
                title=""
                type="error"
                :description="$t('PARAMETERS.REFERENCIAL.ERROR_REF_TABLE')"
                :closable="false"
              />
            </div>
          </el-form-item>

          <!-- DATE PICKER -->
          <el-form-item
            v-if="parameter.displayed && parameter.type === 6"
            :label="parameter.display_name ? parameter.display_name : parameter.parameter"
            :rules="{validator: validateDate, name: parameter.display_name, trigger: 'change', value: parameter.default_value, required: parameter.required}"
            :prop="`parameters[${index}].display_name`"
          >
            <el-date-picker
              v-model="parameter.default_value"
              type="date"
              placeholder=""
              value-format="yyyy-MM-dd"
            />
          </el-form-item>

          <!-- TEXTAREA -->
          <el-form-item
            v-if="parameter.displayed && parameter.type === 7"
            :label="parameter.display_name ? parameter.display_name : parameter.parameter"
            :required="parameter.required"
            :prop="`parameters[${index}].display_name`"
          >
            <el-input
              v-model="parameter.default_value"
              type="textarea"
              placeholder=""
              :maxlength="parameter.maxlength"
            />
          </el-form-item>
        </div>
      </div>
      <el-form-item v-if="optinTypes.length > 0">
        <el-button
          round
          type="primary"
          :loading="startingCalculation"
          @click="submitForm('form')"
        >
          Pre-calculate
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import Navigation from '@/components/Navigation'
import EmptyList from '@/components/EmptyList'
import Processing from '@/components/Templates/Processing'
import config from '@/config'

export default {
  name: 'ParametersPage',
  components: { Navigation, EmptyList, Processing },
  data () {
    return {
      errorParams: [],
      clientId: '',
      client: null,
      templateId: '',
      template: null,
      loadingValues: false,
      loading: false,
      loadingCalculate: false,
      newRun: false,
      currentSelectedParameter: null,
      totalParameters: 0,
      optinTypes: [],
      applications: [],
      form: {
        client_id: null,
        template_id: null,
        optinType: "",
        parameters: [],
        sortieCible: '0',
        includeNonContactable: '0',
        triggerId: null,
        appId: null
      },
      rules: {},
      newSearch: false,
      stopCheckStatus: null,
      loadingCancellation: false,
      currentToken: null,
      startingCalculation: false
    }
  },
  computed: {
    currentTemplate () {
      return this.$store.getters['templates/currentTemplate']
    },
    currentResults () {
      return this.$store.getters['results/currentResults']
    },
    isAPITrigger () {
      return this.template && this.template.campaign_type_id === 4
    },
    isPushNotification () {
      return this.template && this.template.channel_id === config.CHANNELS.PUSH_NOTIFICATION
    },
    isEmail () {
      return this.template && this.template.channel_id === config.CHANNELS.EMAIL
    },
    optinTypeIsRequired () {
      const optinParameter = this.form.parameters.find((p) => p.parameter === 'id_typeconsentement')
      return optinParameter && optinParameter.required
    }
  },
  beforeMount () {
    this.loading = true
    this.clientId = this.$route.params.clientId
    this.templateId = this.$route.params.templateId
    this.form.client_id = this.clientId
    this.form.template_id = this.templateId
    this.getClient()
    this.getTemplate()
  },
  beforeRouteLeave (to, from, next) {
    this.stopCheckStatus = true
    // Before exit page, remove current results parameters
    this.$store.dispatch('results/setCurrentResults', null)
    next()
  },
  methods: {
    // Custom validation trick to bypass the date validation issue
    validateDate (rule, value, callback) {
      if (rule.value === null && rule.required === true) {
        return callback(new Error(rule.name + ' is required'))
      }
      return callback()
    },
    shouldDisplayDropdown (parameter) {
      if (!parameter.displayed) {
        return false
      }
      return parameter.type === config.PARAM_TYPE_ID.DROPDOWN_LIST || parameter.parameter === 'id_typeconsentement' || parameter.parameter === 'NomFichier'
    },
    buildOptionLabel (parameter, item) {
      return parameter.parameter !== 'NomFichier' ? item.id + ' - ' + item.content : item.id + ' - ' + item.name
    },
    remoteMethod (query) {
      // Hide all msg error
      let msgErrorDom = document.getElementsByClassName('parameters-form__msg')
      for (let i = 0; i < msgErrorDom.length; i++) {
        msgErrorDom[i].className = 'parameters-form__msg'
      }
      if ((this.currentSelectedParameter && this.newSearch) || query) {
        this.newSearch = false
        this.loadingValues = true
        setTimeout(() => {
          this.getValues(this.currentSelectedParameter, query, (values) => {
            // Empty results display error message
            if (values && values.length === 0 && query === '') {
              let refName = 'parameter-' + this.currentSelectedParameter.id
              this.$refs[refName][0].className = this.$refs[refName][0].className + ' active'
            }
            for (let i = 0; i < this.form.parameters.length; i++) {
              if (this.form.parameters[i].id === this.currentSelectedParameter.id) {
                this.form.parameters[i].values = values
                this.loadingValues = false
              }
            }
          })
        }, 100)
      }
    },
    focusSelect (item) {
      if (!this.currentSelectedParameter || (this.currentSelectedParameter && item.id !== this.currentSelectedParameter.id) || item.default_value === '') {
        this.newSearch = true
      }
      this.currentSelectedParameter = item
      this.remoteMethod('')
    },
    getClient () {
      this.$store.dispatch('clients/getItem', this.clientId)
        .then(response => {
          this.client = response
          this.getParameters()
        })
        .catch(() => {
          this.error_client = true
        })
    },
    getTemplate () {
      this.$store.dispatch('templates/getItem', { clientId: this.clientId, templateId: this.templateId })
        .then(response => {
          this.template = response
          if (this.template.results && !this.template.results.finished_at) {
            this.loadingCalculate = true
            this.currentToken = this.template.results.token_id
            // Call the status API to test if proc is finished
            const callbackFn = (response) => {
              this.processResponse(response, this.currentToken, callbackFn)
            }
            this.checkStatus(this.currentToken, callbackFn)
          }
          this.getOptinTypes()
          if (this.isPushNotification) {
            this.getPushApplications()
          }
        })
        .catch(() => {
          this.error_client = true
        })
    },
    getParameters () {
      this.$store.dispatch('templates/getParameters', { clientId: this.clientId, templateId: this.templateId })
        .then(response => {
          this.loading = false
          for (let i = 0; i < response.length; i++) {
            let param = response[i]
            if (param.displayed) {
              this.totalParameters++
            }
            // If user wants to change the parameters from the results page, display the current ones
            if (this.currentResults &&
              this.currentResults.template.id.toString() === this.currentTemplate.templateId.toString() &&
              this.currentTemplate.templateId.toString() === this.templateId.toString() &&
              this.currentTemplate.clientId.toString() === this.clientId.toString()) {
              let resultsParameters = this.currentResults.parameters
              for (let j = 0; j < resultsParameters.length; j++) {
                if (resultsParameters[j].parameter.toLowerCase() === param.parameter.toLowerCase() &&
                (resultsParameters[j].parameter.toLowerCase() !== 'id_mailingtelmobile' &&
                resultsParameters[j].parameter.toLowerCase() !== 'id_mailingpostal' &&
                resultsParameters[j].parameter.toLowerCase() !== 'id_mailing' &&
                resultsParameters[j].parameter.toLowerCase() !== 'campaign_id')) {
                  // Sortie cible
                  if (param.parameter === 'sortiecible') {
                    this.form.sortieCible = resultsParameters[j].value
                  }
                  // Optin types
                  if (param.parameter === 'id_typeconsentement') {
                    this.form.optinType = parseInt(resultsParameters[j].value)
                  }
                  // Trigger ID
                  if (param.parameter === 'trigger_id') {
                    this.form.triggerId = resultsParameters[j].value
                  }
                  // Include non-contactable addresses
                  if (param.parameter === 'include_noncontactable') {
                    this.form.includeNonContactable = resultsParameters[j].value
                  }
                  // PN Applications
                  if (param.parameter === 'DDL_Apps_id') {
                    this.form.appId = parseInt(resultsParameters[j].value_id)
                  }
                  // Multi selection, add array
                  if (param.type === 5) {
                    if (param.values) {
                      param.values.push({
                        'id': resultsParameters[j].value_id,
                        'content': resultsParameters[j].value
                      })
                    } else {
                      param.values = [{
                        'id': resultsParameters[j].value_id,
                        'content': resultsParameters[j].value
                      }]
                    }
                    if (param.default_value && Array.isArray(param.default_value)) {
                      param.default_value.push(resultsParameters[j].value_id)
                    } else {
                      param.default_value = [resultsParameters[j].value_id]
                    }
                  } else if (param.type === 1 || param.type === 2 || param.type === 3 || param.type === 6) {
                    param.default_value = resultsParameters[j].value
                  } else {
                    param.default_value = resultsParameters[j].value_id
                  }
                }
              }
            }
            // Note (@soro) necessary because null values are transformed to zero by el-input-number
            if (param.type === config.PARAM_TYPE_ID.NUMBER_INPUT && param.default_value === null) {
              param.default_value = undefined
            }
          }
          this.form.parameters = response
        })
        .catch(() => {
          this.loading = false
        })
    },
    getOptinTypes () {
      this.$store.dispatch('parameters/getOptinTypes', { clientId: this.clientId, channelId: this.template.channel_id })
        .then(response => {
          this.optinTypes = response
          if (this.optinTypes && this.optinTypeIsRequired.value) {
            this.form.optinType = this.optinTypes[0].crm_id
          }
        })
    },
    getPushApplications () {
      this.$store.dispatch('parameters/getPushApplications', { clientId: this.clientId })
        .then(response => {
          this.applications = response
        })
    },
    getValues (parameter, query, callback) {
      // For each parameter, get values if type 4 or 5
      if (parameter.parameter === 'id_typeconsentement') {
        // Get optin types values
        this.$store.dispatch('parameters/getOptinTypes', { data: { search: query }, clientId: this.clientId, channelId: this.template.channel_id })
          .then(response => {
            callback(response.data)
          })
      } else if (parameter.type === 4 || parameter.type === 5 || parameter.parameter === 'NomFichier') {
        // Get parameters values
        this.$store.dispatch('parameters/getParameterValues', { data: { search: query, channel_id: this.template.channel_id }, clientId: this.clientId, parameterId: parameter.id })
          .then(response => {
            callback(response.data)
          })
          .catch(() => {
            this.errorParams.push(this.currentSelectedParameter.id)
            this.loadingValues = false
          })
      }
    },
    checkStatus (token, callback) {
      this.$store.dispatch('results/checkStatus', { clientId: this.clientId, tokenId: token })
        .then(response => {
          callback(response)
        })
    },
    processResponse (response, token, callbackFn) {
      if (this.stopCheckStatus) {
        return false
      }
      if (!response || !response.finished_at) {
        window.setTimeout(() => {
          this.checkStatus(token, callbackFn)
        }, 3000)
      } else {
        this.$router.push({
          name: 'template-results',
          params: {
            clientId: this.clientId,
            templateId: this.templateId,
            resultId: response.id,
            sortieCible: this.form.sortieCible
          }
        })
      }
    },
    submitForm (formName) {
      this.startingCalculation = true
      this.$refs[formName].validate((valid) => {
        let submitForm = {
          clientId: this.clientId,
          templateId: parseInt(this.templateId),
          optinType: this.form.optinType !== "" ? this.form.optinType : null,
          parameters: []
        }

        // Create the params list to submit
        for (let i = 0; i < this.form.parameters.length; i++) {
          let currentParam = this.form.parameters[i]
          if (currentParam.parameter === 'Id_typeConsentement') {
            currentParam.default_value = this.form.optinType
          }
          if (currentParam.parameter === 'sortiecible') {
            currentParam.default_value = this.form.sortieCible
          }
          if (currentParam.parameter === 'include_noncontactable') {
            currentParam.default_value = this.form.includeNonContactable
          }
          if (currentParam.parameter === 'trigger_id') {
            currentParam.default_value = this.form.triggerId
          }
          if (currentParam.parameter === 'DDL_Apps_id') {
            currentParam.default_value = this.form.appId
          }

          // If is multi selection values , create a param for each value
          if (currentParam.default_value && Array.isArray(currentParam.default_value)) {
            for (let j = 0; j < currentParam.default_value.length; j++) {
              let childParam = currentParam.default_value[j]
              let param = {
                parameter_id: currentParam.id,
                name: currentParam.parameter,
                display_name: currentParam.display_name,
                displayed: currentParam.displayed,
                type_id: currentParam.type,
                ref_id: childParam != null ? childParam : null,
                ref_description: currentParam.ref_tablename,
                value: childParam
              }
              submitForm.parameters.push(param)
            }
          } else {
            let param = {
              parameter_id: currentParam.id,
              name: currentParam.parameter,
              display_name: currentParam.display_name,
              displayed: currentParam.displayed,
              type_id: currentParam.type,
              ref_id: currentParam.default_value ? currentParam.default_value : null,
              ref_description: currentParam.ref_tablename
            }
            if (currentParam.default_value ||
                (currentParam.type === config.PARAM_TYPE_ID.NUMBER_INPUT &&
                 currentParam.default_value === 0)) {
              param.value = currentParam.default_value
            }
            submitForm.parameters.push(param)
          }
        }

        if (valid) {
          this.$store.dispatch('templates/calculate', submitForm)
            .then(response => {
              this.loadingCalculate = true
              this.newRun = true

              this.currentToken = response
              // Call the status API to test if proc is finished
              const callbackFn = (response) => {
                this.processResponse(response, this.currentToken, callbackFn)
              }
              this.checkStatus(this.currentToken, callbackFn)
            })
            .catch(() => {
              this.loadingCalculate = false
              this.newRun = false
              this.startingCalculation = false
            })
        } else {
          this.loadingCalculate = false
          this.newRun = false
          this.startingCalculation = false
          return false
        }
      })
    },
    cancelCalculation() {
      this.loadingCancellation = true;
      var data = {
        "clientId": this.clientId,
        "templateId": this.templateId,
        "tokenId": this.currentToken
      }

      this.$store.dispatch('templates/cancel',  data)
        .then(() => {
          const callbackFn = (response) => {
            this.processResponse(response, this.currentToken, callbackFn)
          }
          this.checkStatus(this.currentToken, callbackFn)
          this.loadingCancellation = false;

        })
        .catch(() => {
          this.loadingCancellation = false;
        })
    }
  }
}
</script>
<style lang="scss">
@import "src/assets/style/_variables.scss";
.parameters-page {
  .parameters-form {
    margin: 0 auto;
    min-height: 300px;
    .el-form-item {
      margin-bottom: 30px;
    }
    .el-radio__label {
      color: #888888;
    }
    .el-form-item__label {
      color: #888888;
      font-size: 16px;
      padding-right: 20px;
    }
    .el-select,
    .el-input-number {
      width: 100%;
    }
    &__config-error {
      margin-top: 5px;
      line-height: normal;
      font-size: 14px;
    }
    &__msg {
      line-height: normal;
      display: none;
      font-size: 14px;
      &.active {
        display: block;
        margin-top: 5px;
      }
    }
  }

  .cancel-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px auto;
  }
}
</style>
